import React from "react"
import tw from "twin.macro"
import styled from "styled-components"

import Main from "../components/layouts/Main"
import Header from "../components/layouts/Header"
import Row from "../components/layouts/Row"
import Footer from "../components/layouts/Footer"
import { useStaticQuery, graphql } from "gatsby"
import MarkdownRenderer from "react-markdown-renderer"
import SEO from "../components/Seo"

const Title = styled.h1`
    ${tw`text-3xl md:text-6xl font-primary font-black dark:text-gray-300 text-gray-700 tracking-tight`}
    span {
        background: var(--pop-gradient);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

const Version = styled.p`
    ${tw`text-gray-300 uppercase`}
`

const Section = styled.section`
    ${tw`py-12 min-h-screen relative z-10 rounded-b-2rem bg-white dark:bg-black`}
`

const Content = styled.div`
    ${tw`lg:w-3/4 dark:text-white text-gray-700`}
    p {
        ${tw`dark:text-gray-100 text-gray-700`}
    }
`

const Privacy = (props) => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            datoCmsPrivacy {
                title
                lastEdited(formatString: "DD MMM YYYY")
                content
                # _updatedAt
                # content(locale: en, markdown: true)
            }
        }
    `)
    return (
        <Main>
            <Header />
            <SEO />
            <Section>
                <Row css={tw`mt-40`}>
                    <div>
                        <Title>{data.datoCmsPrivacy.title}</Title>
                        <Version>Version 0.1</Version>
                        <p>Last updated: {data.datoCmsPrivacy.lastEdited}</p>
                    </div>
                </Row>

                <Row>
                    <Content>
                        <MarkdownRenderer
                            markdown={data.datoCmsPrivacy.content}
                        />
                    </Content>
                </Row>
            </Section>
            <Footer />
        </Main>
    )
}

export default Privacy
